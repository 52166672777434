var Events = {
  init: function () {
    this.getNextAndPrevEvents();
    this.changeVisualizationType('.events-pos .icons-nav .fa', '.events-pos .events-loader');
    this.changeVisualizationType('.home__widgets-item--events .icons-nav .fa', '.home__widgets-item--events .events-loader');
  },

  // Altera a visualização da lista de eventos (lista, calendário, etc)
  changeVisualizationType: function (iconsTypeElem, loaderElem) {
    var iconNav = iconsTypeElem;

    $(iconNav).click(function () {
      var itemShow = $(this).attr('data-show');
      var itemHide = $(this).attr('data-hide');
      var loader = $(loaderElem);

      if($(itemShow).css('display') !== 'block') {
        $(iconNav).removeClass('active');
        $(this).addClass('active');

        $(itemHide).slideUp('fast', function () {
          loader.show(0, function () {

            setTimeout(function () {
              loader.hide(0, function () {
                $(itemShow).slideDown('fast');
              });
            }, 300);

          });
        });
      }
    });
  },
  getNextAndPrevEvents: function () {
    $('body').on('click', '#prev-event-calendar', function(e) {
      e.preventDefault();

      var themeUrl = GeneralConfig.themeUrl || '';
      var prevMonth = $(this).data('prev-month');
      var year = $(this).data('year');
      var $target = $('.events-calendar');
      var $loader = $('.events-loader');

      $target.fadeOut('fast', function() {
        $target.html('');
        $loader.fadeIn();
      });

      $.ajax({
        url: themeUrl + '/calendar/events.php',
        method: 'GET',
        data: { month : prevMonth, year : year },
        dataType: 'html'
      }).done(function(html) {
        $('.events-calendar')
          .html(html)
          .promise()
          .done(function () {
            $loader.fadeOut(200, function () {
              $target.fadeIn('fast');
            })
          });
      });
    });

    $('body').on('click', '#next-event-calendar', function(e) {
      e.preventDefault();

      var themeUrl = GeneralConfig.themeUrl || '';
      var prevMonth = $(this).data('next-month');
      var year = $(this).data('year');
      var $target = $('.events-calendar');
      var $loader = $('.events-loader');

      $target.fadeOut('fast', function() {
        $target.html('');
        $loader.fadeIn();
      });

      $.ajax({
        url: themeUrl + '/calendar/events.php',
        method: 'GET',
        data: { month : prevMonth, year : year },
        dataType: 'html'
      }).done(function(html) {
        $('.events-calendar')
          .html(html)
          .promise()
          .done(function () {
            $loader.fadeOut(200, function () {
              $target.fadeIn('fast');
            })
          });
      });
    });
  }
};