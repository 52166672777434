var Map = {
  geocodeAddress: function (geocoder, resultsMap) {
    var address = MapConfig.andress;
    var marker;

    geocoder.geocode({
      'address': address
    }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        resultsMap.setCenter(results[0].geometry.location);

        setTimeout(function () {
          marker = new google.maps.Marker({
            map: resultsMap,
            position: results[0].geometry.location,
            animation: google.maps.Animation.DROP
          });
        }, 1500);
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
  },

  // Esse método é chamado pela própria API do Google Maps
  init: function() {
    var map;
    var geocoder;
    var scrollMap = false;
    var myLatLng = new google.maps.LatLng(MapConfig.lat, MapConfig.long);
    var mapElem = document.getElementById('map');

    // Chama o mapa apenas na página em que a div #map existir.
    if (mapElem === null) {
      return false;
    }

    map = new google.maps.Map(mapElem, {
      center: myLatLng,
      zoom: 16
    });

    geocoder = new google.maps.Geocoder();
    this.geocodeAddress(geocoder, map);

    map.setOptions({'scrollwheel': scrollMap});
    // map.addListener('click', function () {
    //   scrollMap = !scrollMap;
    //   map.setOptions({'scrollwheel': scrollMap})
    // });
  }
};