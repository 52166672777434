var Documents = {
  init: function () {
    this.getDocuments()
  },

  getDocuments: function () {
    var themeUrl = GeneralConfig.themeUrl || '';
    var $target = $('#docum0ents-list');
    var $loader = $('.gabinete .gabinete__list .loader');
    var $alert = $('.gabinete__list .alert');

    var data = {
      year: parseInt($('#ano').val()) || 0,
      month: parseInt($('#mes').val()) || 0,
      category: $('#selected-category').val() || ''
    };

    $alert.remove();
    $target.html('');

    if ($target.length > 0) {
      $loader.fadeIn(0, function () {
        $.ajax({
          url: themeUrl + '/documents/list.php',
          method: 'GET',
          data: data,
          dataType: 'html'
        }).done(function(html) {
          var parsed = JSON.parse(html);

          if ($alert.length > 0 ) {
            $alert.remove();
          }

          $loader.hide();
          if (parsed['error'] !== '') {
            $target
              .parents('.gabinete__list')
              .append(parsed['error']);
          } else {
            $target
              .html(parsed['html']);
          }
        });
      });
    }
  }
};

var Staff = {
  init: function () {
    this.getStaffPaginated();
  },

  getStaffPaginated: function () {
    var themeUrl = GeneralConfig.themeUrl || '';
    var $target = $('.professores .professores__list');
    var $loader = $('.professores .staff-loader');
    var data = {
      actualPage: parseInt($('#actual_page').val()) || 1,
      perPage: parseInt($('#per_page').val()) || 10,
      searchText: $('#search_text').val() || '',
      postId: parseInt($('#post_id').val()) || 0
    };

    $loader.fadeIn(0, function () {
      $.ajax({
        url: themeUrl + '/staff/teachers-list.php',
        method: 'GET',
        data: data,
        dataType: 'html'
      }).done(function(html) {
        var parsed = JSON.parse(html);

        if (parsed['error'] !== '') {
          parsed['html'] = parsed['error'];
          parsed['endOfArray'] = true;
        }

        if (parsed['endOfArray']) {
          $('.professores__list--btn-box').hide();
        } else {
          $('.professores__list--btn-box').show();
        }

        $('#actual_page').val(data.actualPage + 1);

        $target
          .append(parsed['html'])
          .promise()
          .done(function () {
            $loader.hide()
            $(this)
              .children('div')
              .fadeIn(300);
          });
      });
    })
  }
};

var Menus = {
  toggleMenu: function () {
    var dataAttr = 'data-target-dropdown';
    var elem = $('[' + dataAttr + ']');

    elem.each(function () {
      $(this).click(function (e) {
        e.preventDefault();

        if ($(window).width() < 992) {
          $($(this).attr(dataAttr)).slideToggle(500);
        }
      });

    });
  },
  showSecondDropdownMenu: function () {
    var dataAttr = 'data-menu-dropdown-link';
    var dataAttrHide = 'data-menu-dropdown-link-hide';
    var elem = $('[' + dataAttr + ']');

    elem.each(function () {
      $(this).click(function (e) {
        var $that = $(this);

        e.preventDefault();

        $($(this).attr(dataAttrHide)).fadeOut(240, function () {
          $($that.attr(dataAttr)).fadeIn(500);
        });
      });
    });
  },
  loadMenuAcademico: function() {
    var themeUrl = GeneralConfig.themeUrl || '';
    var data = {
      slug: 'academico',
      getFrom: 1
    };

    $.ajax({
      url: themeUrl + '/json/menu-academico.json',
      method: 'GET'
    }).done(function(data) {
      $('#dropdown-header-academico > ul').html(data);
    });
  },
  loadMenuPublicacoes: function() {
    var themeUrl = GeneralConfig.themeUrl || '';
    var data = {
      slug: 'publicacoes',
      getFrom: 1
    };

    $.ajax({
      url: themeUrl + '/json/menu-publicacoes.json',
      method: 'GET'
    }).done(function(data) {
      $('#dropdown-header-publicacoes > ul').html(data);
    });
  },
  loadMenuEstude: function() {
    var themeUrl = GeneralConfig.themeUrl || '';
    var data = {
      slug: 'estude-conosco',
      getFrom: 1
    };

    $.ajax({
      url: themeUrl + '/json/menu-estude-conosco.json',
      method: 'GET'
    }).done(function(data) {
      $('#dropdown-header-estude > ul').html(data);
    });
  },
  loadMenuPrincipal: function(options) {
    var themeUrl = GeneralConfig.themeUrl || '';
    var data = {
      slug: options.slug,
      getFrom: options.siteId
    };

    $.ajax({
      url: themeUrl + '/json/' + options.file,
      method: 'GET'
    }).done(function(data) {
      $('.header .header__navigation-nav > ul')
        .html(data)
        .promise()
        .done(function() {
          Menus.buildBigMenu();
          Menus.toggleMenu();
        });
    });
  },
  loadMenus: function() {
    var options = {
      slug: 'principal',
      siteId: 1,
      file: 'menu-principal.json'
    };

    if ($('.header .header__navigation-nav').hasClass('nead')) {
      options.slug = 'nead';
      options.siteId = 28;
      options.file = 'menu-nead.json';
    }

    this.loadMenuAcademico();
    this.loadMenuPublicacoes();
    this.loadMenuEstude();
    this.loadMenuPrincipal(options);
  }
};

var Banners = {
  animateBigBanner: function () {
    $('.big-banner__items').cycle({
        fit             : 1 ,
        slideResize     : 0 ,
        containerResize : 0 ,
        speed           : 800 ,
        swipe           : true ,
        swipeFx         : 'scrollHorz',
        slides          : '.item' ,
        pager           : '.big-banner__pagination'
    });
  },

  animateGalery: function () {
    $('.nead .galery-list ul').cycle({
        carouselVisible : 4 ,
        carouselFluid   : true ,
        speed           : 800 ,
        swipe           : true ,
        swipeFx         : 'carousel',
        fx              : 'carousel',
        slides          : '> li',
        pager           :'#pager'
    });
  },

  galery: function () {
    var item = $('.nead .galery-list li');

    item.click(function () {
      var content = $(this).find('img').attr('src');
      var title = $(this).find('.title').text();
      var text = $(this).find('.text').text();

      $('.galery-nead').attr('style', 'background-image:url(' + content + ');');
      $('.galery-nead .galery-title').text(title);
      $('.galery-nead .galery-title + p').text(text);
    })
  }
};

var Social = {
  reloadFacebookEmbed: function (wrapElem, loader, baseHeight) {
    var facebookUser = SocialConfig.facebookUser || 'facebook'
      , embed = '<div class="fb-page" data-href="https://www.facebook.com/' + facebookUser + '" data-width="360" data-height="358"data-small-header="false" data-adapt-container-width="true" data-hide-cover="false"data-show-facepile="true" data-show-posts="true"> <div class="fb-xfbml-parse-ignore"> <blockquote cite="https://www.facebook.com/' + facebookUser + '"> <a href="https://www.facebook.com/' + facebookUser + '">Facebook</a> </blockquote> </div> </div>'
      , width = 0
      , height = 0;

    $('.fb-page').fadeOut();

    if (loader) {
      loader = $(wrapElem).parent().find('.loader');
      loader.fadeIn(200);
    }

    setTimeout(function () {
      width = $(wrapElem).width();
      height = $(baseHeight).height();

      if (height > 300) {
        height = 300;
      }

      $(wrapElem).html(embed)
        .promise()
        .done(function () {
          if (loader) {
            loader.fadeOut(50);
          }

          $(wrapElem).find('.fb-page')
            .attr('data-width', width)
            .attr('data-height', (height + 3));

          FB.XFBML.parse();

        });
    }, 1000);
  },

  loadInstagramFeed: function () {
    var instagramOptions = {
      resolution: 'low',
      get: 'user',
      userId: parseInt(SocialConfig.instagramUserId),
      accessToken: SocialConfig.instagramUserToken,
      limit: 4,
      template: '<div><a target="_blank" href="{{link}}"><img src="{{image}}" alt="Instagram - Foto Atlantico Sul" /></a></div>'
    };

    $('.blog .widget--instagram .feed').ifeed(instagramOptions);
  }
};

var Videos = {
  cropTitle: function(element, target){
    var title = $(element).attr("data-title");
    var crop;
    var limit;

    if (typeof title !== 'undefined') {
      if (window.innerWidth > 992) {
        limit = 35;
      }else if((window.innerWidth > 767) && (window.innerWidth <= 992)){
        limit = 25;
      }

      crop = title.substring(0, limit);

      if(title.length > limit){
        $(target).find('.title-area').text(crop + "...");
      }else{
        $(target).find('.title-area').text(title);
      }
    }

  },

  videoShowZomm: function (element, target) {
    $(element).click(function () {
      var url   = $(this).attr("data-url");
      var title = $(this).attr("data-title");

      $(target).find('iframe').attr("src", url);
      $(target).find('iframe').attr("data-title", title);
      $(target).find('.title-area').text(title);

      Videos.cropTitle($(this), '.home__videos-featured');
    });
  },

  setTitle: function(target){
    var titleActive = $(target).find('iframe').attr("data-title");

    $(target).find('.title-area').text(titleActive);
  },

  cropResize: function(title){
    Videos.cropTitle(title, '.home__videos-featured');
  },

  stopOnClose: function () {
    $(".modal").on('hidden.bs.modal', function (e) {
      $(".modal-dialog iframe").attr("src", $(".modal-dialog iframe").attr("src"));
    });
  }
};

var App = {
  init: function () {
    $('a[href="#"]').click(function (e) {
      e.preventDefault();
    });

    $('[data-toggle="tooltip"]').tooltip();

    $('.nav-tabs a').click(function (e) {
      e.preventDefault();
      $(this).tab('show');
    });

    Menus.showSecondDropdownMenu();
    Menus.toggleMenu();
    Menus.loadMenus();
    Videos.videoShowZomm('.other-video li', '.home__videos-featured');
    Videos.setTitle('.home__videos-featured');
    Videos.stopOnClose();
    Banners.animateBigBanner();
    Banners.animateGalery();
    Banners.galery();
    Events.init();
    Staff.init();
    Social.loadInstagramFeed();
    Documents.init();

    setTimeout(function () {
      Social.reloadFacebookEmbed('.widget--facebook .embed', true, '.widget--instagram .feed');
    }, 300);
  }
};


$(document).ready(function() {
  App.init();
});


window.addEventListener('resize', function () {
  Videos.cropResize($('.home__videos-featured iframe'));

  setTimeout(function () {
    Social.reloadFacebookEmbed('.widget--facebook .embed', true, '.widget--instagram .feed');
  }, 300);
});


window.addEventListener('popstate', function (e) {
});